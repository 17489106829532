<template>
  <v-row>
    <v-dialog v-model="HintLigar" scrollable persistent max-width="700px">
      <v-card>
        <v-toolbar dense flat
          ><h3>Petunjuk Permainan</h3>
          <v-spacer></v-spacer>
          <h4 v-if="!close">Silakan tunggu... {{ counting }}</h4>
          <v-btn text v-if="close" @click="$emit('false')"
            >tutup <v-icon color="red">mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <div class="py-3 px-5 d-flex flex-column align-center">
          <p>
            Pada permainan ini Anda diharuskan menyusun pipa hingga air dapat
            mengalir ke dalam ember. <b>Klik pipa</b> untuk mengubah posisi
            pipa.
          </p>
          <section class="d-flex">
            <img
              src="../../../assets/img/pipe-tutorial01.png"
              height="200px"
              class="mr-3"
              alt=""
            />
            <img
              src="../../../assets/img/pipe-tutorial02.png"
              height="200px"
              alt=""
            />
          </section>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "LigarHint",
  props: ["HintLigar"],
  data() {
    return {
      close: false,
      counting: null
    };
  },
  mounted() {
    this.timerClose();
  },
  methods: {
    timerClose() {
      let sec = 15;
      console.log(sec);
      let min = setInterval(() => {
        sec--;
        this.counting = sec;
        if (sec === 0) {
          this.close = true;
          clearInterval(min);
        }
      }, 1000);
    }
  }
};
</script>

<style></style>
